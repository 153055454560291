<template>
  <div>
    <el-form :model="form"
             label-width="80px"
             ref="formRef">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="科目：">
            <el-select v-model="form.subject_id"
                       filterable
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="状态:">
            <el-select v-model="form.status"
                       filterable
                       clearable
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="item in statusList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="反馈时间:">
            <el-date-picker v-model="dates"
                            style="width:100%"
                            @focus="$forbid"
                            type="datetimerange"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            range-separator="至"
                            start-placeholder="开始日期"
                            @change="search"
                            :default-time="['00:00:00', '23:59:59']"
                            end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button type="primary"
                     @click="search">查询</el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-table border
              stripe
              :data="tableData"
              highlight-current-row
              :max-height="$store.state.tableHeight + 30"
              style="width: 100%">
      <el-table-column prop="question_error_id"
                       v-if="tableColumns[0].isShow"
                       align="center"
                       label="编号" />
      <el-table-column prop="user_id"
                       v-if="tableColumns[1].isShow"
                       align="center"
                       label="反馈人ID" />
      <div v-if="userInfo.is_parttime == 0">
        <el-table-column prop="user_name"
                         v-if="tableColumns[2].isShow"
                         align="center"
                         label="姓名" />
      </div>
      <el-table-column prop="subject_name"
                       v-if="tableColumns[3].isShow"
                       align="center"
                       label="科目" />
      <el-table-column prop="question_id"
                       v-if="tableColumns[4].isShow"
                       align="center"
                       label="题目ID" />
      <el-table-column prop="category"
                       v-if="tableColumns[5].isShow"
                       align="center"
                       label="反馈类型">
        <template slot-scope="{row}">
          {{row.category?'批改错误':'题目报错'}}
        </template>
      </el-table-column>
      <el-table-column prop="comment"
                       v-if="tableColumns[6].isShow"
                       align="center"
                       label="反馈内容" />
      <el-table-column prop="question_error_time"
                       v-if="tableColumns[7].isShow"
                       align="center"
                       label="反馈时间" />
      <el-table-column prop="is_teacher"
                       v-if="tableColumns[8].isShow"
                       align="center"
                       label="是否教师">
        <template slot-scope="{row}">
          <span :style="{color:row.is_teacher?'#20C192':'#FE4C4C'}">
            {{row.is_teacher?'是':'否'}}
          </span>
        </template>
      </el-table-column>
      <div v-if="userInfo.is_parttime == 0">

        <el-table-column prop="input_user_id"
                         v-if="tableColumns[9].isShow"
                         align="center"
                         label="录入人/批改人ID" />
        <el-table-column prop="edu_user_name"
                         v-if="tableColumns[10].isShow"
                         align="center"
                         label="录入人/批改人姓名" />
      </div>
      <el-table-column prop="status_detail"
                       v-if="tableColumns[11].isShow"
                       align="center"
                       label="状态">

        <template slot-scope="{row}">
          <div :class="getStatusClass(row.status)">
            {{row.status_detail}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="teacher_comment"
                       v-if="tableColumns[12].isShow"
                       align="center"
                       label="反驳内容" />
      <el-table-column align="center"
                       width="200"
                       v-if="tableColumns[13].isShow"
                       label="操作">
        <template slot="header">
          <i class="el-icon-setting"
             slot="header"
             @click="showController"></i>
          操作
        </template>
        <template slot-scope="{row}">
          <div class="operyion_btn_icon">
            <el-tooltip effect="dark"
                        content="查看"
                        placement="top-start">
              <img src="@/assets/icons/icon-priview@2x.png"
                   style="margin-right:10px"
                   @click="checkInfo(row)"
                   alt="">
            </el-tooltip>
            <el-tooltip effect="dark"
                        content="修改"
                        placement="top-start">
              <img src="@/assets/icons/icon-修改题目@2x.png"
                   @click="editTopic(row)"
                   v-if="row.status == 1 "
                   style="margin-right:10px"
                   alt="">
            </el-tooltip>
            <el-tooltip effect="dark"
                        content="修改题头"
                        placement="top-start">
              <img src="@/assets/icons/icon-edit@2x.png"
                   style="margin-right:10px"
                   v-if="row.status == 1&& !row.category && row.question_parent_id"
                   @click="editTopicHead(row.question_parent_id)"
                   alt="">
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />

    <InfoForm ref="InfoFormRef">
    </InfoForm>
    <Shuttle ref="ShuttleRef" />

  </div>
</template>

<script>
import InfoForm from './components/info.vue'

export default {
  components: {
    InfoForm
  },
  data () {
    return {
      comment: '',
      statusList: [
        { label: '未通过', value: -1 },
        { label: '待审核', value: 0 },
        { label: '已通过', value: 1 },
        { label: '已完成', value: 2 },
      ],
      dates: [],
      form: {
        subjectId: '',
        status: '',
        beganTime: '',
        category: '',
        audit: 0,
        limit: 10,
        page: 1,
      },
      subjectList: [],
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      pageName: 'cz_feedback_list_admin',
      tableColumns: [
        { label: '编号', isShow: true },
        { label: '反馈人', isShow: true },
        { label: '姓名', isShow: true },
        { label: '科目', isShow: true },
        { label: '题目ID', isShow: true },
        { label: '反馈类型', isShow: true },
        { label: '反馈内容', isShow: true },
        { label: '反馈时间', isShow: true },
        { label: '是否教师', isShow: true },
        { label: '录入人ID', isShow: true },
        { label: '录入人姓名', isShow: true },
        { label: '状态', isShow: true },
        { label: '反驳内容', isShow: true },
        { label: '操作', isShow: true },
      ],
      userInfo: {},
      categoryList: [
        { label: '题目报错', value: 0 },
        { label: '批改错误', value: 1 },
      ],
      info: {},
      isPG: false
    }
  },
  created () {
    var info = window.localStorage.getItem(this.pageName)
    if (info) {
      this.tableColumns = JSON.parse(info)
    }
  },
  mounted () {
    this.initSelect()
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))

  },
  methods: {
    editTopic (item) {
      if (item.category == 1) {
        this.$router.push('/checkTestAll/checkTestPage?id=' + item.question_error_id + '&isErr=1')
      } else {
        this.$router.push('/examinationEntry/examinationEntry/edit?id=' + item.question_id)
      }
    },
    checkInfo (item) {
      this.$refs.InfoFormRef.getInfo(item)
    },
    initData (page, limit) {
      this.form.page = page
      this.form.limit = limit

      let form = JSON.parse(JSON.stringify(this.form))
      if (this.dates && this.dates.length) {
        form.begin_time = this.dates[0]
        form.end_time = this.dates[1]
      }
      this.$http({

        url: '/api/v1/error/lst',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    delRow (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/file/del',
          method: 'post',
          data: {
            id: row.id
          }
        }).then(() => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.search()
        })
      })
    },
    // 查询
    search (e) {
      this.$refs.pagination.toFirstPage()
    },
    initSelect () {
      this.$http({
        url: '/api/v1/papers/subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.subject
      })
    },

    showController () {
      this.$refs.ShuttleRef.columns = JSON.parse(JSON.stringify(this.tableColumns))
      this.$refs.ShuttleRef.dialogVisible = true
    },
    getColumns (arr) {
      this.tableColumns = arr
      window.localStorage.setItem(this.pageName, JSON.stringify(arr))
      this.$forceUpdate()
    },
    getStatusClass (status) {
      if (status == -1) {
        return 'wtg status'
      }
      if (status == 0) {
        return 'wsh status'
      }

      return 'tg status'
    },
    editTopicHead (id) {
      this.$router.push('/examinationEntry/examinationEntry/edit?id=' + id)
    }
  }
}
</script>

<style lang="scss" scoped>
.subject {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}
.weishanhe {
  color: #3f9eff;
  background: #eff8ff;
  padding: 0px 10px;
  display: inline-block;
  border-radius: 5px;
}

.pass {
  color: #41d7d7;
  background: #effffe;
  padding: 0px 10px;
  display: inline-block;
  border-radius: 5px;
}

.notpass {
  color: #fe765f;
  background: #ffefef;
  padding: 0px 10px;
  display: inline-block;
  border-radius: 5px;
}

.btn_warp {
  width: 100%;
  display: flex;
  justify-content: center;
  .btn {
    padding: 5px 30px;
    border-radius: 15px;
    font-size: 13px;
    font-weight: bold;
    color: #ffffff;
  }
  .primary {
    background: #3f9eff;
  }
  .danger {
    background: #fe765f;
  }
}

.status {
  padding: 5px 10px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  width: 80px;
  margin: 0 auto;
}
.wsh {
  background: #e9f1fd;
  color: #3f9eff;
}
.tg {
  background: #cff7ea;
  color: #20c192;
}
.wtg {
  background: #fde9e9;
  color: #fe4c4c;
}
.comment {
  font-size: 16px;
  font-weight: bold;
  color: black;
}
</style>